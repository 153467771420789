import React from "react";
import SeoTextHelper from "./components/SeoTextHelper";

export default function App() {
  return (
    <div className="App">
      <SeoTextHelper />
    </div>
  );
}
