import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ProgressBar from './ProgressBar';
import Keyword from './Keyword';

export default function SeoTextHelper() {
  // Copy To Clipboard State
  // eslint-disable-next-line
  const [copied, setCopied] = useState(false);
  // eslint-disable-next-line
  const [data, setData] = useState('');
  // eslint-disable-next-line
  const handleData = (event) => {
    setCopied(false);
    setData(event.target.value);
  };

  // Counts
  const [count, setCount] = useState(0);
  const [maxCount, setMaxCount] = useState(130);

  // Keywords
  const [keywords, setKeywords] = useState([{ name: 'Intention' }]);
  // const [keywords, setKeywords] = useState(new Set([{ name: 'Intention' }]));
  const [newKeyword, setNewKeyword] = useState('');

  // Text
  const [seoText, setSeoText] = useState('');

  // Add keyword
  const add = (keyword) => setKeywords([...keywords, keyword]);

  // Remove keyword
  const remove = (index) => {
    setKeywords([...keywords.slice(0, index), ...keywords.slice(index + 1)]);
  };

  const handleAddClick = () => {
    if (newKeyword === '') {
      return;
    }
    add({ name: newKeyword });
    setNewKeyword('');
  };

  // Handle add newKeyword when enter is pressed
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddClick();
    }
  };

  // Remove duplicate values from seoKeywordGroup
  const seoKeywordGroup = [...new Set(keywords.map((keyword) => keyword.name))];

  // // Search and compare Keywords with Textfield
  // let searchString = seoKeywordGroup;
  // let seoPlainTextField = seoText.toString().toLowerCase().split(',');

  //eslint-disable-next-line
  // let result = seoPlainTextField.filter((seoKeywordGroup) => {
  //   let containsAtLeastOneWord = false;

  //   // If a word is matched return it
  //   searchString.forEach((word) => {
  //     if (seoKeywordGroup.toString().toLowerCase().includes(word))
  //       containsAtLeastOneWord = true;
  //   });
  //   console.log('containsAtLeastOneWord: ', containsAtLeastOneWord);

  //   if (containsAtLeastOneWord) return seoKeywordGroup;

  // });

  // Progressbar count and color
  let barColor = 'green';
  let countBar = ((count / maxCount) * 100).toFixed(0);
  let countBarMax = (count / maxCount) * 100;

  // if count is 70% of maxCount set barColor to orange
  if (count > maxCount * 1) {
    barColor = 'orange';
  }

  // if count is 100% of maxCount set barColor to red
  if (count > maxCount * 1.2) {
    barColor = 'red';
  }

  // (optional) if count 120% of maxCount set max percentage display to 120
  // if (count >= maxCount * 1.2) {
  //   // countBar = 120;
  // }

  // if count 120% of maxCount set max color bar width value to 120
  if (count >= maxCount * 1.2) {
    countBarMax = 120;
  }

  const progressBar = [
    { bgcolor: barColor, completed: countBar, completedColor: countBarMax },
  ];
  // End Search and compare Keywords with Textfield
  return (
    // Wrapper
    <div className='flex justify-center'>
      <div className='max-w-5xl sm:w-11/12  sm:mt-10  p-3 my-2 sm:p-8 rounded-md bg-gray-300'>
        {/* Headline */}
        <h1 className='font-semibold uppercase text-center'>SEO Text Helper</h1>

        {/* Keywords */}
        <div className='flex self-start flex-col mt-4'>
          <p className='text-sm font-bold uppercase'> Keywords: </p>

          {/* Keyword-list */}
          <div className='flex self-start flex-wrap pb-5 w-10/12 '>
            {seoKeywordGroup.map((keyword, index) => {
              return (
                <Keyword
                  key={index}
                  keyword={keyword}
                  seoText={seoText}
                  onRemove={() => remove(index)}
                />
              );
            })}
          </div>

          {/* Keyword-input */}
          <div className='flex justify-end text-center'>
            <input
              className='rounded py-1 px-2 '
              type='text'
              value={newKeyword}
              onChange={(event) => setNewKeyword(event.target.value.trim())}
              // if press enter add keyword
              onKeyDown={handleKeyDown}
            />

            {/* Button add keyword */}
            <button
              className=' bg-white ml-2 border-sm rounded border-gray-300 p-1 pr-2 pl-2 hover:bg-green-500 hover:text-white'
              onClick={handleAddClick}
            >
              Add keyword
            </button>
          </div>
        </div>

        <form>
          <div className='flex'>
            {/* Input characters */}
            <input
              className='mt-2 px-2 text-center rounded border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50'
              type='text'
              pattern='[0-9]*'
              size='3'
              value={maxCount}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) {
                  return;
                } else {
                  setMaxCount(e.target.value.trim());
                }
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />{' '}
            <p className='flex flex-col text-sm pt-2 font-bold uppercase pl-1.5 '>
              {' '}
              Characters{' '}
              <span className='text-xs lowercase font-thin'>
                (only Numbers)
              </span>
            </p>
          </div>

          <div className='pt-2 pl-0.5 pb-0.5 text-xs uppercase'>
            {/* if count is 20% greater than maxCount change text line */}
            {count > maxCount * 1.2 ? (
              <p>
                Characters: {count} / Max: {maxCount} /{' '}
                <span className='text-red-500'>
                  Over Limit: {(maxCount - count).toString().replace('-', '')}
                </span>
              </p>
            ) : // if count is greater than maxCount change "over limit" text to orange
            count > maxCount ? (
              <p>
                {' '}
                Characters: {count} / Max: {maxCount} /{' '}
                <span className='text-yellow-500'>
                  {' '}
                  Over Limit:
                  {(maxCount - count).toString().replace('-', '')}
                </span>
              </p>
            ) : (
              <p>
                Characters: {count} / Max: {maxCount} / Left: {maxCount - count}
              </p>
            )}{' '}
          </div>

          {/* Text field */}
          <textarea
            type='text'
            rows={4} // eslint-disable-next-line
            className='px-2 py-1 w-full h-60 text-left rounded border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50'
            onChange={(e) =>
              setCount(e.target.value.length) & setSeoText(e.target.value)
            }
          />
        </form>

        {/* Copy to clipboard */}
        <div className='flex justify-end pt-2'>
          <CopyToClipboard text={seoText} onCopy={() => setCopied(true)}>
            <button className='bg-white border-sm rounded border-gray-300 p-1 px-2 hover:bg-green-500 hover:text-white'>
              Click to Copy
            </button>
          </CopyToClipboard>
        </div>

        {/*Progress Bar */}
        <div className='pr-1'>
          {progressBar.map((item, id) => (
            <ProgressBar
              key={id}
              bgcolor={item.bgcolor}
              completed={item.completed}
              completedColor={item.completedColor}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
